import ComputerIcon from '../images/home/icon-desktop.svg';
import WebIcon from '../images/home/icon-web.svg';
import MobileIcon from '../images/home/icon-mobile.svg';
import TestimonialJoe from '../images/home/testimonial-joe-tex-air.jpg'
import TestimonialKelly from '../images/home/testimonial-kelly-equipt.jpg'
import { Colors } from '../theme';

let HomePageData = {
	hero: {
		title: 'Web Design \n & Development',
    subheader: "Services",
    body: 'These days, you want your party up front and business in the back. When you work with Thumbs Up Studio, you get it all—a product where the latest web & digital technologies go to work behind the finest of finishes. Let’s go.',
    buttonTitle: "See the work"
  },
  services: {
		title1: 'Form.',
		title2: 'Meet Function.',
    subheader: "Services",
    body: 'These days, you want your party up front and business in the back. When you work with Thumbs Up Studio, you get it all—a product where the latest web & digital technologies go to work behind the finest of finishes. Let’s go.',
    service: [
      {
        image: ComputerIcon,
        title: "digital",
        body: "All encompassing. If it’s in the digital world, we left a thumb print on it at some point. If you need to know how to get your business in the digital age—we’re the people you want to talk to."
      },
      {
        image: WebIcon,
        title: "web",
        body: "We’ve been building custom websites for our clients for over 8+ years. Whether you need Wordpress or a completely custom solution—we have the experience to build what you need."
      },
      {
        image: MobileIcon,
        title: "mobile",
        body: "The best time to get your business on App Store or Google Play was 5 years ago, the next best time is now. We know what it takes to bring your business to the main stage."
      },
    ]
  },
  caseStudies: {
		title1: 'Our Work.',
		title2: 'Out There.',
    subheader: "Case Studies",
    body: 'Check out how we helped these businesses reach the next level.',
  },
  ourProcess: {
    title1: 'Trust.',
		title2: 'The Process.',
    subheader: "How We Work",
    body: "Tried. True. Proven. Our process is not entirely unique to us, but how we approach each step is. With a deep dive into your business's current and future goals, we ensure the product you get — is the one you need.",
    process: [
      {
        title: 'Strategize',
        subItems: ['Consulting', 'Research', 'Wireframing & UX', 'Branding'],
        color: Colors.Blue
      },
      {
        title: 'Design',
        subItems: ['Prototyping', 'User Interface', 'Design Systems', 'Rapid Iteration'],
        color: Colors.DeepPurple
      },
      {
        title: 'Develop',
        subItems: ['HTML / CSS / JS', 'Wordpress / CMS', 'AWS / Netlify', 'SEO'],
        color: Colors.LightPurple
      },
      {
        title: 'Support',
        subItems: ['Maintenance', 'Backups', 'Updates', 'Analytics'],
        color: Colors.Purple
      }
    ]
  },
  testimonials: {
		title1: 'Words from',
		title2: 'The Wise.',
    subheader: "Testimonials",
    testimonial: [
      {
        quote: '“You really are helping us get our name out there and I am so freaking pumped.”',
        name: 'Joe Prowell',
        title: 'President',
        company: 'Tex Air Corp.',
        image: TestimonialJoe,
      },
      {
        quote: '“Thumbs Up Studio took the time to really understand our vision, our brand, and the business goals we were looking to achieve with this website launch. With Eric\'s help we now have a website that works for us and our customers.”',
        name: 'Kelly Mooney',
        title: 'CEO / Founder',
        company: 'Equipt Women',
        image: TestimonialKelly,
      },
      {
        quote: '“You really are helping us get our name out there and I am so freaking pumped.”',
        name: 'Joe Prowell',
        title: 'President',
        company: 'Tex Air Corp.',
        image: TestimonialJoe,
      },
      {
        quote: '“You really are helping us get our name out there and I am so freaking pumped.”',
        name: 'Joe Prowell',
        title: 'President',
        company: 'Tex Air Corp.',
        image: TestimonialJoe,
      },
    ]
  },
};

export { HomePageData };
