import React from "react";
import styled from "styled-components";
import { HomeHero } from "../components/Heros";
import { Header, Body, TextBlock } from "../components/Typography";
import { Colors } from "../theme/Colors";
import { SiteContent } from "../components/Layouts";
import Seo from "../components/seo";
import { HomePageData } from "../data/home-page";
import { CaseStudyData } from "../data/case-studies";
import { CaseStudySlider, TestimonialSlider } from "../components/Sliders";
import { NewLink } from "../components/Buttons";
import { ProcessCard } from "../components/Cards";
import QuoteSvg from "../images/home/quote.svg";
import "../theme/css/animations.css";
import "animate.css/animate.min.css";
// import Imac from "../images/home/thumbs-up-studio-imac.png";
// import {
//   motion,
//   useViewportScroll,
//   useTransform,
// } from "framer-motion";

const Wrapper = styled.div`
  overflow: hidden;
`;

const HomeWrapper = styled.div`
  height: 100%;
  background-color: ${Colors.DarkPurple};
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 192px;
  margin-top: 64px;
  gap: 32px;
`;

const ProcessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 128px;
  width: 100%;
  flex-wrap: wrap;
`;

const ServicesColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
  justify-content: center;
  align-items: center;
`;

const ServiceIcon = styled.img`
  max-width: 64px;
  margin-bottom: 32px;
`;

const CaseStudyCTA = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Purple}1f;
  border-radius: 16px;
  width: 30%;
  min-width: 300px;
  margin: 0 auto;
  padding: 16px 32px;
  margin-bottom: 192px;
  text-align: center;
  gap: 8px;
`;

const Quote = styled.img`
  width: 25px;
  height: 25px;
  margin-bottom: 16px;
`;
// const MacImage = styled(motion.img)`
//   width: 80%;
//   margin: 0 auto;
// `;

const IndexPage = (className) => {
  const { services, caseStudies, ourProcess, testimonials } = HomePageData;
  // const { scrollYProgress } = useViewportScroll();
  // const scale = useTransform(scrollYProgress, [1, 1], [1, 1]);

  // const scrollYProgress = useMotionValue(10);
  // const xRange = [200, 200, -200, -100];
  // const opacityRange = [0, 1, 1, 0];
  // const scale = useTransform(scrollYProgress, xRange, opacityRange);

  return (
    <Wrapper>
      <HomeWrapper>
        <Seo title="Thumbs Up Studio — Web Design & Development — Buffalo, NY & Tampa Bay, FL" />
        {/* Hero */}

        <HomeHero />
        <ContentWrapper>
          {/* Services */}
          <div>
            <SiteContent
              style={{ zIndex: 2, marginBottom: "64px"}}
              center
            >
              <TextBlock
                textAlign="center"
                subheader={services.subheader}
                title1={services.title1}
                title2={services.title2}
                body={services.body}
              />
              <ServicesWrapper>
                {services.service.map((data, index) => {
                  return (
                    <ServicesColumn key={index + data.title}>
                      <ServiceIcon alt={data.image} src={data.image} />
                      <Header h3>{data.title}</Header>
                      <Body align="center">{data.body}</Body>
                    </ServicesColumn>
                  );
                })}
              </ServicesWrapper>
            </SiteContent>
          </div>
          {/* Case Studies */}
          <SiteContent style={{ zIndex: 2 }} center>
            <TextBlock
              textAlign="center"
              color={Colors.Aqua}
              subheader={caseStudies.subheader}
              title1={caseStudies.title1}
              title2={caseStudies.title2}
              body={caseStudies.body}
            />
          </SiteContent>
          <CaseStudySlider data={CaseStudyData} />
          <SiteContent style={{ zIndex: 2 }} center>
            <CaseStudyCTA>
              <Header h3 sofia>
                Want to see more?
              </Header>
              <NewLink to="/work/" center>
                View All Work
              </NewLink>
            </CaseStudyCTA>
          </SiteContent>

          {/* Process */}
          <SiteContent
            center
            style={{
              zIndex: 2,
              marginBottom: "64px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <TextBlock
              textAlign="center"
              colorSwap
              color={Colors.Pink}
              subheader={ourProcess.subheader}
              title1={ourProcess.title1}
              title2={ourProcess.title2}
              body={ourProcess.body}
            />

            <ProcessWrapper>
              {ourProcess.process.map((data, index) => {
                return (
                  <ProcessCard
                    key={index + data.title}
                    index={index}
                    data={data}
                  />
                );
              })}
            </ProcessWrapper>
          </SiteContent>
          {/* <motion.div style={{ scale }}>
            <MacImage
              alt="imac display"
              src={Imac}
              style={{
                scale: scrollYProgress,
              }}
            />
          </motion.div> */}

          {/* Testimonials */}
          <SiteContent style={{ zIndex: 2, textAlign: "center" }} center>
            <Quote
              data-aos="zoom-in"
              data-aos-duration={800}
              data-aos-delay={400}
              data-aos-once="true"
              alt="quotation mark"
              src={QuoteSvg}
            />
            <TextBlock
              textAlign="center"
              colorSwap
              color={Colors.Purple}
              subheader={testimonials.subheader}
              title1={testimonials.title1}
              title2={testimonials.title2}
              body={testimonials.body}
            />
          </SiteContent>
          <TestimonialSlider data={testimonials.testimonial} />
        </ContentWrapper>
      </HomeWrapper>
    </Wrapper>
  );
};

export default IndexPage;
